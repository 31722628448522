import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Email from '../components/Email';
import Website from '../components/Website';

import styles from './members-table.module.css';

const MembersTablePage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Members' Table">
        <div className={styles.flex}>
          <Img fluid={data.image.childImageSharp.fluid} className={styles.image} />
          <div className={styles.flexText}>
            <p>
              We invite you to join the 2020 Mount Mary Members Table when you
              place an order during this year’s release period of six or more
              (750ml) bottles of the 2017 new release wines. Membership is valid
              for a 12 month period and includes:
            </p>
            <ul className={styles.benefits}>
              <li>Release pricing on all Mount Mary Vineyard new release wines during our annual release period this year</li>
              <li>Your personal log-in to our Members’ Table website with access to:</li>
              <li>Online ordering (includes free shipping on orders of 12 bottles or more, outside of the release period)</li>
              <li>Our complete newsletter archive dating back to 1981 Tasting notes of back vintage wines spanning more than 35 years.</li>
              <li>Invitations to members’ only events throughout the year including our annual Members’ Table Dinner</li>
            </ul>
            <p>
              If you would like to enquire more about becoming part of our Members’
              Table, please email <Email /> or visit <Website />.
            </p>
          </div>
        </div>
      </Section>
      <Pagination
        forwardLink="/closing"
        backLink="/release"
      />
    </Layout>
  );
};

MembersTablePage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "MT.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MembersTablePage;
