import React from 'react';

import styles from './Website.module.css';

const WEBSITE_ADDRESS = 'www.mountmary.com.au';

const Website = () => (
  <a
    href={`https://${WEBSITE_ADDRESS}`}
    className={styles.link}
  >
    {WEBSITE_ADDRESS}
  </a>
);

export default Website;
